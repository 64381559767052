import React from 'react';

import { TFunction } from 'i18next';

import { Box, Grid } from '@mui/material';

import { ImagePreviewCircle } from '@components/Drawers/TkEditTaskDrawer/styles';
import { TkIcon, TkTypography } from '@components/index';
import { ExtendedDrawersTypes } from '@hooks/drawer/drawer.types';

interface Image {
  fullImageUrl: string;
}

interface TaskImageEditorProps {
  isProvidedDrawerOpen: (drawerType: ExtendedDrawersTypes) => boolean;
  images: Image[];
  imagesFile: File[];
  handleImages: () => void;
  t: TFunction;
  isStatusDone?: boolean;
}

const TaskImageEditor: React.FC<TaskImageEditorProps> = ({
  isProvidedDrawerOpen,
  images,
  imagesFile,
  handleImages,
  t,
  isStatusDone = false,
}) => {
  return (
    <TkTypography
      fontSize={16}
      fontFamily="Lato"
      fontWeight="normal"
      color={
        isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_IMAGES)
          ? 'white'
          : 'default'
      }
      display="inline"
    >
      <Grid container justifyContent="space-between">
        <Box width="auto">
          <Box fontSize={16} display="inline" marginRight="5px">
            <TkIcon
              className="fa fa-images"
              verticalAlign="middle"
              width={19}
              color={
                isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_IMAGES)
                  ? 'white'
                  : 'default'
              }
              fontSize="inherit"
            />
          </Box>
          {t('guidance_images', { ns: 'common' })}:
        </Box>
        {images.length > 0 ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={handleImages}
          >
            {images.map(
              (i, index) =>
                index <= 1 && <ImagePreviewCircle src={i.fullImageUrl} />
            )}
            {imagesFile.map(
              (i, index) =>
                index <= 1 && (
                  <ImagePreviewCircle src={URL.createObjectURL(i)} />
                )
            )}
            <TkTypography
              fontSize={16}
              display="inline"
              fontFamily="Lato"
              fontWeight="bold"
              textAlign="right"
              style={{ cursor: 'pointer' }}
              color={
                isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_IMAGES)
                  ? 'white'
                  : 'default'
              }
              marginLeft={1}
            >
              {images.length + imagesFile.length}
            </TkTypography>
          </Box>
        ) : (
          <Box onClick={handleImages}>
            <TkTypography
              fontSize={16}
              fontWeight="bold"
              fontFamily="Lato"
              userselect="none"
              color={
                isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_IMAGES)
                  ? 'white'
                  : 'primary'
              }
              textAlign="right"
              style={{ cursor: 'pointer' }}
            >
              {!isStatusDone ? (
                imagesFile.length === 0 ? (
                  `+ ${t('images', { ns: 'common' })}`
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    onClick={handleImages}
                  >
                    {imagesFile.map((i, index) =>
                      index <= 2 ? (
                        <ImagePreviewCircle src={URL.createObjectURL(i)} />
                      ) : (
                        ''
                      )
                    )}
                    <TkTypography
                      fontSize={16}
                      display="inline"
                      fontFamily="Lato"
                      fontWeight="bold"
                      textAlign="right"
                      style={{ cursor: 'pointer' }}
                      color={
                        isProvidedDrawerOpen(
                          ExtendedDrawersTypes.EDIT_TASK_IMAGES
                        )
                          ? 'white'
                          : 'default'
                      }
                      marginLeft={1}
                    >
                      {imagesFile.length}
                    </TkTypography>
                  </Box>
                )
              ) : (
                images.length
              )}
            </TkTypography>
          </Box>
        )}
      </Grid>
    </TkTypography>
  );
};

export default TaskImageEditor;
