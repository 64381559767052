import React from 'react';

import { TFunction } from 'react-i18next';

import { Box, Grid } from '@mui/material';

import { TkIcon, TkTypography } from '@components/index';
import { TaskStatusEnum } from '@consts/index';
import { ExtendedDrawersTypes } from '@hooks/drawer/drawer.types';
import { TkIconSelected } from '@web-ui/index';

interface CustomerInfoProps {
  event: IEvent;
  customer: ICustomerTask;
  showEditCustomer: boolean;
  isStatusTodo: boolean;
  t: TFunction;
  isProvidedDrawerOpen: (drawer: ExtendedDrawersTypes) => boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  handleCustomer: () => void;
  removeCustomer: () => void;
}

const CustomerInfo: React.FC<CustomerInfoProps> = ({
  event,
  customer,
  showEditCustomer,
  isStatusTodo,
  t,
  isProvidedDrawerOpen,
  onMouseEnter,
  onMouseLeave,
  handleCustomer,
  removeCustomer,
}) => {
  return (
    <TkTypography
      fontSize={16}
      fontFamily="Lato"
      fontWeight="normal"
      color={
        isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_CUSTOMER)
          ? 'white'
          : 'default'
      }
      display="inline"
    >
      <Grid
        container
        justifyContent="space-between"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {customer ? (
          <>
            <Box
              width="auto"
              position="relative"
              sx={{ maxWidth: isStatusTodo ? '350px' : 'none' }}
            >
              <Box fontSize={16} display="inline" marginRight="5px">
                <TkIcon
                  className="fa fa-user-tie"
                  color={
                    isProvidedDrawerOpen(
                      ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                    )
                      ? 'white'
                      : 'default'
                  }
                  fontSize="inherit"
                />
              </Box>
              {t('customer', { ns: 'common' })}:
              <TkTypography
                fontSize={16}
                display="inline"
                fontFamily="Lato"
                fontWeight="bold"
                color={
                  isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_CUSTOMER)
                    ? 'white'
                    : 'default'
                }
                marginLeft={1}
              >
                {customer.name}
                {showEditCustomer && isStatusTodo ? (
                  <TkIconSelected
                    className="fa fa-pencil"
                    verticalAlign="middle"
                    onClick={handleCustomer}
                  />
                ) : (
                  ''
                )}
              </TkTypography>
            </Box>
            {isStatusTodo ? (
              <Box fontSize={16}>
                <TkIcon
                  style={{ cursor: 'pointer' }}
                  className="fa fa-trash"
                  onClick={removeCustomer}
                  color={
                    isProvidedDrawerOpen(
                      ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                    )
                      ? 'white'
                      : 'default'
                  }
                  fontSize="inherit"
                />
              </Box>
            ) : (
              ''
            )}
          </>
        ) : (
          <>
            <Box width="auto">
              <Box fontSize={16} display="inline" marginRight="5px">
                <TkIcon
                  className="fa fa-user-tie"
                  color={
                    isProvidedDrawerOpen(
                      ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                    )
                      ? 'white'
                      : 'default'
                  }
                  fontSize="inherit"
                />
              </Box>
              {t('customer', { ns: 'common' })}:
            </Box>
            {event.status === TaskStatusEnum.IN_PROGRESS ? (
              ''
            ) : (
              <Box>
                <TkTypography
                  fontSize={16}
                  fontWeight="bold"
                  fontFamily="Lato"
                  userselect="none"
                  onClick={handleCustomer}
                  color={
                    isProvidedDrawerOpen(
                      ExtendedDrawersTypes.EDIT_TASK_CUSTOMER
                    )
                      ? 'white'
                      : 'primary'
                  }
                  textAlign="right"
                  style={{ cursor: 'pointer' }}
                >
                  + {t('customer', { ns: 'common' })}
                </TkTypography>
              </Box>
            )}
          </>
        )}
      </Grid>
    </TkTypography>
  );
};

export default CustomerInfo;
