import React, { useState, useEffect } from 'react';

import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Grid } from '@mui/material';

import {
  TkExtendedWrapper,
  WrapperTeammatesList,
  WrapperTeammatesItem,
  ImgAssignee,
  WrapperTeammatesHeader,
  WrapperTeammatesImagesList,
  TkListActions,
  OverlayContainer,
  OverlayWrapper,
  CircleButton,
  IconAssigneeButton,
} from '../styles';
import { ImagePreview } from '@components/TkDragNDrop/styles';
import { DynamicButton, TkIcon, TkTypography } from '@components/index';
import { useDrawer } from '@hooks/drawer';
import {
  editEventTeammatesImageCount,
  getEventCrewAssignmentsImages,
  getEventId,
} from '@recoilData/index';
import api from '@services/api';

const TkTeammatesImages = (): JSX.Element => {
  const { t } = useTranslation();
  const { closeExtDrawer } = useDrawer();
  const [showOverlay, setShowOverlay] = useState(false);
  const [blockDownload, setBlockDownload] = useState(false);
  const [selectedImg, setSelectedImg] = useState('');
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedAssignment, setSelectedAssignment] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const teammatesImages = useRecoilValue(getEventCrewAssignmentsImages);
  const eventId = useRecoilValue(getEventId);
  const [assignmentIds, setAssignmentIds] = useState<number[]>([]);
  const imgCount = useRecoilValue(editEventTeammatesImageCount);

  useEffect(() => {
    const ids: number[] = [];

    teammatesImages.forEach((teammatesImages) => {
      ids.push(teammatesImages.id);
    });

    setAssignmentIds(ids);
  }, [teammatesImages]);

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  const handleDownloadImage = () => {
    saveAs(selectedImg, 'download.jpg');
  };

  const handleDownloadAssignmentImages = async () => {
    setBlockDownload(true);
    api
      .get(
        `image/download/assignment/${selectedAssignment}?withMembersImages=true`,
        {
          responseType: 'blob',
        }
      )
      .then((res) => {
        const blob = new Blob([res.data]);
        saveAs(blob, `teammates_images_assignment_${selectedAssignment}.zip`);
        setBlockDownload(false);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const handleDownloadAllImages = async () => {
    setBlockDownload(true);
    const requests: Promise<{ id: number; data: any }>[] = [];
    assignmentIds.forEach((assignmentId) => {
      requests.push(
        api
          .get(
            `image/download/assignment/${assignmentId}?withMembersImages=true`,
            {
              responseType: 'blob',
            }
          )
          .then((res) => {
            return {
              id: assignmentId,
              data: res.data,
            };
          })
          .catch(() => {
            return {
              id: 0,
              data: null,
            };
          })
      );
    });

    await Promise.all(requests).then((requests) => {
      const contents: any[] = [];

      requests.forEach((request) => {
        contents.push(request.data);
      });

      const blob = new Blob(contents);
      saveAs(blob, `${eventId}_teammates_images.zip`);

      setBlockDownload(false);
    });
  };

  const handleSelectedImage = (
    id: number,
    index: number,
    img: string,
    assignment: number
  ) => {
    setSelectedImg(img);
    setSelectedItem(id);
    setSelectedIndex(index);
    setShowOverlay(true);
    setSelectedAssignment(assignment);
  };

  const getAssignmentImageCount = () => {
    const assignment = teammatesImages.find(
      (assignment) => assignment.id === selectedAssignment
    );

    if (!assignment) {
      return 0;
    }

    return assignment.images.length;
  };

  const handleHorizontalScroll = (
    e: React.WheelEvent<HTMLDivElement>,
    id: number
  ) => {
    e.preventDefault();
    const container = document.getElementById(`img-list-${id}`);
    if (container !== null) {
      const containerScrollPosition = container.scrollLeft;
      container.scrollTo({
        top: 0,
        left: containerScrollPosition + e.deltaY,
        behavior: 'auto',
      });
    }
  };

  const handleChangeImage = (more: boolean) => {
    const images = teammatesImages.find((i) => i.userId === selectedItem);

    if (more) {
      const image = images?.images[selectedIndex + 1];
      if (image !== undefined) {
        setSelectedIndex(selectedIndex + 1);
        setSelectedImg(image.fullImageUrl);
      }
    } else {
      const image = images?.images[selectedIndex - 1];
      if (image !== undefined) {
        setSelectedIndex(selectedIndex - 1);
        setSelectedImg(image.fullImageUrl);
      }
    }
  };

  return (
    <>
      {showOverlay ? (
        <OverlayWrapper>
          <OverlayContainer backgroundImage={selectedImg}>
            <Grid
              container
              justifyContent="flex-end"
              style={{ padding: '15px' }}
            >
              <CircleButton onClick={handleCloseOverlay}>
                <TkIcon iconSize={20} color="#ffffff" className="fa fa-times" />
              </CircleButton>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              style={{ padding: '15px' }}
            >
              <CircleButton
                style={{ cursor: 'pointer' }}
                onClick={() => handleChangeImage(false)}
              >
                <TkIcon
                  iconSize={20}
                  marginRight={3}
                  color="#ffffff"
                  className="fa fa-chevron-left"
                  cursor="pointer"
                />
              </CircleButton>
              <CircleButton
                style={{ cursor: 'pointer' }}
                onClick={() => handleChangeImage(true)}
              >
                <TkIcon
                  iconSize={20}
                  color="#ffffff"
                  marginLeft={3}
                  className="fa fa-chevron-right"
                  cursor="pointer"
                />
              </CircleButton>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              style={{ padding: '15px' }}
            >
              <DynamicButton
                variant="contained"
                bgcolor="secondary"
                disableElevation
                onClick={handleDownloadImage}
              >
                <TkTypography
                  fontWeight="normal"
                  fontFamily="Lato"
                  fontSize={16}
                >
                  {t('downloadItem', {
                    ns: 'common',
                    item: t('image', { ns: 'common' }).toLowerCase(),
                  })}
                </TkTypography>
              </DynamicButton>
              <DynamicButton
                variant="contained"
                bgcolor="primary"
                disableElevation
                onClick={
                  blockDownload ? () => null : handleDownloadAssignmentImages
                }
              >
                <TkTypography
                  fontWeight="normal"
                  fontFamily="Lato"
                  fontSize={16}
                >
                  {blockDownload
                    ? t('downloading', { ns: 'common' })
                    : t('downloadXItems', {
                        ns: 'common',
                        count: getAssignmentImageCount(),
                        item: t('images', { ns: 'common' }).toLowerCase(),
                      })}
                </TkTypography>
              </DynamicButton>
            </Grid>
          </OverlayContainer>
        </OverlayWrapper>
      ) : null}
      <>
        <TkExtendedWrapper maxWidth="xl">
          <WrapperTeammatesHeader container direction="row" alignItems="center">
            <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={20}>
              {t('teammateImages', { ns: 'events' })}
            </TkTypography>
            <TkIcon
              onClick={closeExtDrawer}
              style={{ cursor: 'pointer' }}
              className="fa fa-chevron-right"
              color="default"
              marginLeft={10}
              verticalAlign="baseline"
              fontSize="small"
            />
          </WrapperTeammatesHeader>
          <WrapperTeammatesList>
            {teammatesImages.map((item) => (
              <WrapperTeammatesItem key={item.id}>
                <Grid container alignItems="center" direction="row">
                  {item.photoUrl !== null ? (
                    <ImgAssignee src={item.photoUrl} style={{ marginTop: 0 }} />
                  ) : (
                    <IconAssigneeButton>
                      <TkIcon className="fa fa-user" iconSize={19} />
                    </IconAssigneeButton>
                  )}
                  <TkTypography
                    fontSize={16}
                    fontWeight="bold"
                    fontFamily="Lato"
                  >
                    {item.fullName}
                  </TkTypography>
                </Grid>
                <Grid container>
                  <WrapperTeammatesImagesList
                    id={`img-list-${item.userId}`}
                    onWheel={(e) => handleHorizontalScroll(e, item.userId)}
                  >
                    {item.images.map((img, index) => (
                      <div
                        key={img.id}
                        style={{
                          margin: '5px',
                        }}
                      >
                        <ImagePreview
                          loading="lazy"
                          style={{ cursor: 'pointer' }}
                          src={img.fullImageUrl}
                          onClick={() =>
                            handleSelectedImage(
                              item.userId,
                              index,
                              img.fullImageUrl,
                              item.id
                            )
                          }
                        />
                      </div>
                    ))}
                  </WrapperTeammatesImagesList>
                </Grid>
              </WrapperTeammatesItem>
            ))}
          </WrapperTeammatesList>
        </TkExtendedWrapper>
        <TkListActions>
          <Grid
            container
            justifyContent="flex-end"
            style={{ paddingRight: '10px' }}
          >
            <DynamicButton
              variant="contained"
              bgcolor="primary"
              disableElevation
              onClick={blockDownload ? () => null : handleDownloadAllImages}
            >
              <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
                {blockDownload
                  ? t('downloading', { ns: 'common' })
                  : t('downloadXItems', {
                      ns: 'common',
                      count: imgCount,
                      item: t('images', { ns: 'common' }).toLowerCase(),
                    })}
              </TkTypography>
            </DynamicButton>
          </Grid>
        </TkListActions>
      </>
    </>
  );
};

export default TkTeammatesImages;
