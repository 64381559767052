import React, { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Channel, DefaultGenerics } from 'stream-chat';
import { Chat, LoadingIndicator, Streami18n } from 'stream-chat-react';

import { ChatCustomContext, IChatContext } from './ChatCustomContext';
import { TaskbirdLogoRound } from '@assets/index';
import { AppLocationURLs } from '@consts/index';
import { useAuth } from '@contexts/AuthContext/AuthProvider';
import { ChatActionTypes } from '@contexts/ChatProvider/types';
import { isChatEnabled } from '@helpers/environment';
import { getQueryParams } from '@helpers/url-helpers';
import { useClient } from '@hooks/chat/useChatClient';
import { useDesktopNotification } from '@hooks/index';
import { EventQueryParamsEnum } from '@hooks/queryParams.type';

const ChatProvider = ({ children }) => {
  const [isNewChannelBeingCreated, setCreateNewChannel] = useState(false);
  const { notify } = useDesktopNotification();
  const { userInfo, signed } = useAuth();
  const { t } = useTranslation('chat');
  const history = useHistory();

  const streami18n = new Streami18n({
    language: 'en',
  });

  const chatClient = useClient({
    user: {
      id: userInfo?.chat_profile?.id,
      name: userInfo?.user?.full_name,
      image:
        userInfo?.user?.full_photo_url ??
        `https://getstream.io/random_svg/?name=${userInfo?.user?.full_name}`,
      role: 'admin',
    },
    tokenOrProvider: userInfo?.chat_profile?.user_token,
  });

  chatClient?.on((event) => {
    if (
      event.type === ChatActionTypes.MESSAGE_NEW &&
      event.message.user.id !== userInfo.chat_profile.id
    ) {
      const message = event.message.text;
      const messageHeader = t('messageNotification.messageFrom', {
        name: event.message.user.name,
      });

      notify(messageHeader, {
        body: message,
        icon: (event.message.user.image as string) ?? TaskbirdLogoRound,
        image: event.message.attachments?.[0]?.image_url ?? '',
        onClick: () =>
          history.push(
            getQueryParams(AppLocationURLs.CHAT, {
              [EventQueryParamsEnum.CHAT_CHANNEL_QUERY_PARAM]: event.channel_id,
            })
          ),
      });
    }
  });

  const createNewChannel = async (
    members: IAssignee[],
    callback: (
      newChannel?: Channel<DefaultGenerics>,
      watchers?: {
        limit?: number;
        offset?: number;
      },
      // eslint-disable-next-line @typescript-eslint/ban-types
      event?: React.BaseSyntheticEvent<object, any, any>
    ) => void
  ) => {
    if (!userInfo.chat_profile.id) return;

    const membersId: string[] = [userInfo.chat_profile.id];

    members.forEach(({ chat_user_id }) => {
      if (!chat_user_id) return;

      membersId.push(chat_user_id);
    });

    if (membersId.length <= 1) {
      setCreateNewChannel(false);
      return;
    }

    const newChannel = await chatClient.channel('messaging', {
      members: membersId,
    });

    setCreateNewChannel(false);
    callback(newChannel);
  };

  const hooks: IChatContext = {
    isNewChannelBeingCreated,
    createNewChannel,
    setCreateNewChannel,
  };

  if (!isChatEnabled || !signed) {
    return children;
  }

  if (isChatEnabled && !chatClient) {
    return <LoadingIndicator />;
  }

  return (
    <ChatCustomContext.Provider value={hooks}>
      <Chat
        client={chatClient}
        theme="str-chat__theme-light"
        i18nInstance={streami18n}
      >
        {children}
      </Chat>
    </ChatCustomContext.Provider>
  );
};

const useCustomChatContext = (): IChatContext => useContext(ChatCustomContext);

export { ChatProvider, useCustomChatContext };
