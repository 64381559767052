import React, { useMemo } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { Box, Grid, Divider } from '@mui/material';

import TkBookingProjectAdditionalInfoItem from './components/TkBookingProjectAdditionalInfoItem';
import {
  TkCustomerInfo,
  TkTaskImageEditor,
  TkDeletedEvent,
  TkLoadingEvent,
  TkEditTaskNotes,
  TkAssigneeSelectedListItem,
  TkEditNameDisplay,
  TkTaskTags,
  TkStatusAndTimeDisplay,
  TkTeamNotesAndImages,
  TkTaskInventory,
  TkTaskChecklists,
} from './components/index';
import { TkTaskWrapperSubItem } from './components/styles';
import { useBookingProject } from './hooks/useBookingProject';
import { useEditTaskDrawer } from './hooks/useEditTaskDrawer';
import {
  TkTaskWrapper,
  TkTaskWrapperItem,
  HorizontalDivider,
  Selector,
  TkTaskActions,
} from './styles';
import {
  TkDrawerHeader,
  TkTaskWrapperItem as TkTaskItem,
} from '@components/TkDrawerComponents';
import { TkDrawerTurnoAdditionalInfo } from '@components/TkDrawerComponents/TkDrawerTurnoAdditionalInfo';
import { ITkExtendedOfferDetailsDrawerEnum } from '@components/TkOfferDetailsDrawer/TkExtendedOffersDetailsDrawer';
import { CrewMember } from '@components/TkTeams/types';
import {
  TkIcon,
  TkTypography,
  DynamicButton,
  TkDrawerPhone,
  TkDrawerLocationDetails,
  TkAssigneeButton,
  TkDrawerServiceDetails,
} from '@components/index';
import { TaskStatusEnum, TaskbirdIntegrationsEnum } from '@consts/index';
import { useAuth } from '@contexts/index';
import { getOfferAdditionalItems } from '@helpers/offers';
import { useStartChat } from '@hooks/index';
import { TkIconSelected } from '@web-ui/index';

const TkEditTaskDrawer = (): JSX.Element => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const loading = queryClient.isFetching('getEvent');
  const {
    event,
    deleted,
    customer,
    selectedEvent,
    allDayEnd,
    allDayStart,
    setAllDayEnd,
    setAllDayStart,
    showEditCustomer,
    handleCustomer,
    removeCustomer,
    assignedUsers,
    assignedUsersCount,
    handleAssignee,
    onMouseEnter,
    onMouseLeave,
    handleLocation,
    handleTaskNotes,
    images,
    imagesFile,
    handleImages,
    edited,
    handleSave,
    handleDismiss,
    openDeleteTaskModal,
    handleProjectAdditionalInfoTurno,
    isProvidedDrawerOpen,
    ExtendedDrawersTypes,
    handleMarkAsComplete,
    handleContactCostumerSupport,
  } = useEditTaskDrawer();

  const { userInfo } = useAuth();
  const { handleStartChat } = useStartChat();
  const { handleProjectAdditionalInfo, handleProjectDetails } =
    useBookingProject();

  const isStatusTodo = event.status === TaskStatusEnum.TODO;
  const isStatusDone = event.status === TaskStatusEnum.DONE;
  const isStatusInProgress = event.status === TaskStatusEnum.IN_PROGRESS;

  const isEventBooking =
    selectedEvent.platform === TaskbirdIntegrationsEnum.BOOKING_WIDGET;
  const isTaskFromMoveout =
    selectedEvent.platform === TaskbirdIntegrationsEnum.MOVEOUT;

  const eligibleAssigneesToChat = assignedUsers.filter(
    (assignee) => assignee.chat_user_id && assignee.id !== userInfo?.user.id
  );

  const shouldShowMarkCompleteForEveryone = useMemo(() => {
    const eventStart = DateTime.fromJSDate(event.start);
    return (
      (DateTime.now() >= eventStart && !isStatusDone) || isStatusInProgress
    );
  }, [event.start, isStatusDone, isStatusInProgress]);

  const handleStartChatWithAssignees = () => {
    const channelID = `task-${selectedEvent.id}-${userInfo?.account.id}`;
    const chatUserIDs = eligibleAssigneesToChat.map((assignee) =>
      assignee.chat_user_id.toString()
    );
    handleStartChat(
      chatUserIDs,
      eligibleAssigneesToChat as CrewMember[],
      channelID
    );
  };

  const assignedUsersInProgressCount = assignedUsers.reduce(
    (count, user) =>
      count + (user.assignmentStatus === TaskStatusEnum.IN_PROGRESS ? 1 : 0),
    0
  );
  const assignedUsersCompletedCount = assignedUsers.reduce(
    (count, user) =>
      count + (user.assignmentStatus === TaskStatusEnum.DONE ? 1 : 0),
    0
  );
  const assignedUsersNotCompletedCount =
    assignedUsers.length - assignedUsersCompletedCount;

  const shouldRemoveEditEventAssignee = (assignee: IAssignee): boolean => {
    if (
      assignedUsersCompletedCount > 0 &&
      assignedUsersNotCompletedCount === 1 &&
      assignee.assignmentStatus !== TaskStatusEnum.DONE
    ) {
      handleMarkAsComplete(true, 'lastNotCompleted');
      return false;
    }
    if (
      assignedUsersInProgressCount === 1 &&
      assignedUsersCompletedCount === 0 &&
      assignee.assignmentStatus === TaskStatusEnum.IN_PROGRESS
    ) {
      handleMarkAsComplete(true, 'lastInProgress');
      return false;
    }
    return true;
  };

  if (loading) {
    return <TkLoadingEvent />;
  } else if (deleted && !loading) {
    return (
      <TkDeletedEvent event={selectedEvent} handleDismiss={handleDismiss} />
    );
  } else {
    return (
      <>
        <TkDrawerHeader
          id={event.id}
          taskId
          showMoreActions={{
            enable: true,
            markCompleteForEveryone: shouldShowMarkCompleteForEveryone,
          }}
          handlers={{
            handleClose: handleDismiss,
            handleDeleteTask: openDeleteTaskModal,
            handleMarkAsComplete,
            handleContactCostumerSupport,
          }}
        />

        <TkTaskWrapper>
          <TkEditNameDisplay />
          <TkTaskTags event={selectedEvent} />
          <TkStatusAndTimeDisplay
            allDayEnd={allDayEnd}
            allDayStart={allDayStart}
            selectedEvent={selectedEvent}
            setAllDayEnd={setAllDayEnd}
            setAllDayStart={setAllDayStart}
          />
          <TkTeamNotesAndImages selectedEvent={selectedEvent} />
          {selectedEvent.inventory && (
            <TkTaskInventory inventory={selectedEvent.inventory} />
          )}
          <TkTaskChecklists
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
          {isEventBooking && (
            <TkTaskWrapperItem padding="5px 1rem">
              <HorizontalDivider />
              <TkTaskWrapperSubItem
                style={{ cursor: 'pointer' }}
                onClick={handleProjectDetails}
                color={
                  isProvidedDrawerOpen(
                    ITkExtendedOfferDetailsDrawerEnum.SERVICE_DETAILS
                  )
                    ? 'selected'
                    : ''
                }
              >
                <TkDrawerServiceDetails price={selectedEvent.rate ?? 0} />
              </TkTaskWrapperSubItem>
            </TkTaskWrapperItem>
          )}
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_CUSTOMER)
                ? 'selected'
                : ''
            }
            padding="10px 1rem"
          >
            <TkCustomerInfo
              event={event}
              customer={customer}
              showEditCustomer={showEditCustomer}
              isStatusTodo={isStatusTodo}
              t={t}
              isProvidedDrawerOpen={isProvidedDrawerOpen}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              handleCustomer={handleCustomer}
              removeCustomer={removeCustomer}
            />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          {selectedEvent.customer?.phone_number && selectedEvent.platform && (
            <>
              <TkTaskWrapperItem padding="10px 1rem 5px">
                <TkDrawerPhone
                  phoneNumber={selectedEvent.customer.phone_number}
                />
              </TkTaskWrapperItem>
              <TkTaskWrapperItem padding="5px 1rem">
                <HorizontalDivider />
              </TkTaskWrapperItem>
            </>
          )}
          <TkTaskWrapperItem
            padding="10px 1rem"
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_LOCATION)
                ? 'selected'
                : ''
            }
          >
            <TkDrawerLocationDetails
              location={selectedEvent.location}
              streetOnly={isTaskFromMoveout}
              selected={isProvidedDrawerOpen(
                ExtendedDrawersTypes.EDIT_TASK_LOCATION
              )}
              editButton={
                <TkIconSelected
                  style={{ cursor: 'pointer' }}
                  className="fa fa-pencil"
                  verticalAlign="middle"
                  onClick={handleLocation}
                />
              }
              editable={isStatusTodo}
            />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.TASK_ASSIGNEE_LIST)
                ? 'selected'
                : ''
            }
            padding="10px 1rem"
          >
            <Box display="flex" justifyContent="space-between">
              <TkTypography fontFamily="Lato" fontSize={16}>
                <TkIcon
                  className="fa fa-user"
                  iconSize={14}
                  color={
                    isProvidedDrawerOpen(
                      ExtendedDrawersTypes.TASK_ASSIGNEE_LIST
                    )
                      ? 'white'
                      : 'default'
                  }
                  marginRight={6}
                />
                {t('assignedTo', { ns: 'events' })}:
              </TkTypography>
              {!isStatusDone && assignedUsersCount >= 1 && (
                <Selector onClick={handleAssignee}>
                  <TkTypography
                    fontWeight="bold"
                    fontFamily="Lato"
                    fontSize={16}
                    userselect="none"
                    color={
                      isProvidedDrawerOpen(
                        ExtendedDrawersTypes.TASK_ASSIGNEE_LIST
                      )
                        ? 'white'
                        : 'primary'
                    }
                  >
                    + {t('teammates', { ns: 'common' })}
                  </TkTypography>
                </Selector>
              )}
            </Box>
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem 5px 1rem">
            {assignedUsers.map((assignee) => (
              <TkAssigneeSelectedListItem
                key={assignee.id}
                assignee={assignee}
                shouldRemoveEditEventAssignee={shouldRemoveEditEventAssignee}
              />
            ))}
            {assignedUsersCount === 0 && (
              <TkAssigneeButton handleClick={handleAssignee} />
            )}
            {eligibleAssigneesToChat.length >= 2 && (
              <TkTypography
                fontWeight="bold"
                fontFamily="Lato"
                fontSize={16}
                color="primary"
                userselect="none"
                marginTop="15px"
                textAlign="center"
                style={{ cursor: 'pointer' }}
                onClick={handleStartChatWithAssignees}
              >
                <TkIcon
                  className="fa fa-comments-alt"
                  color="default"
                  iconSize={16}
                  width={18}
                  marginLeft={10}
                  marginRight={10}
                  style={{ cursor: 'pointer' }}
                />
                {t('message_all_teammates_assigned', { ns: 'common' })}
              </TkTypography>
            )}
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          {selectedEvent.externalId &&
            getOfferAdditionalItems(selectedEvent, false).restItemsCount >
              0 && (
              <div style={{ padding: '.25rem 1rem' }}>
                <TkTaskWrapperItem
                  margin="0 -1rem"
                  padding="0 1rem"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleProjectAdditionalInfoTurno()}
                  bgcolor={
                    isProvidedDrawerOpen(
                      ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_TURNO
                    )
                      ? 'selected'
                      : ''
                  }
                >
                  <TkDrawerTurnoAdditionalInfo offer={selectedEvent} />
                </TkTaskWrapperItem>
                <Divider sx={{ marginTop: '.25rem' }} />
              </div>
            )}
          {isEventBooking && (
            <TkTaskWrapperItem
              padding="0.5rem"
              margin="0.5rem"
              style={{ cursor: 'pointer' }}
              onClick={handleProjectAdditionalInfo}
              bgcolor={
                isProvidedDrawerOpen(
                  ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_BOOKING
                )
                  ? 'filled'
                  : ''
              }
            >
              <TkTaskItem icon="fa fa-hashtag">
                <TkTypography fontSize={16}>
                  {t('additionalInfo', { ns: 'offers' })}
                </TkTypography>
              </TkTaskItem>
              <TkBookingProjectAdditionalInfoItem
                selectedEvent={selectedEvent}
              />
            </TkTaskWrapperItem>
          )}
          <TkTaskWrapperItem
            onClick={handleTaskNotes}
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_NOTES)
                ? 'selected'
                : ''
            }
            padding="10px 1rem"
          >
            <TkTypography
              fontSize={16}
              fontFamily="Lato"
              fontWeight="normal"
              color={
                isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_NOTES)
                  ? 'white'
                  : 'default'
              }
              display="inline"
            >
              <Grid container justifyContent="space-between">
                <Box width="auto" position="relative">
                  <Box fontSize={16} display="inline" marginRight="5px">
                    <TkIcon
                      className="fa fa-file"
                      color={
                        isProvidedDrawerOpen(
                          ExtendedDrawersTypes.EDIT_TASK_NOTES
                        )
                          ? 'white'
                          : 'default'
                      }
                      fontSize="inherit"
                    />
                  </Box>
                  {t('guidance_notes', { ns: 'common' })}:
                </Box>
                <Box fontSize={16}>
                  <TkEditTaskNotes
                    selected={isProvidedDrawerOpen(
                      ExtendedDrawersTypes.EDIT_TASK_NOTES
                    )}
                  />
                </Box>
              </Grid>
            </TkTypography>
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem
            onClick={handleImages}
            bgcolor={
              isProvidedDrawerOpen(ExtendedDrawersTypes.EDIT_TASK_IMAGES)
                ? 'selected'
                : ''
            }
            padding="10px 1rem"
          >
            <TkTaskImageEditor
              isProvidedDrawerOpen={isProvidedDrawerOpen}
              images={images}
              imagesFile={imagesFile}
              handleImages={handleImages}
              t={t}
              isStatusDone={isStatusDone}
            />
          </TkTaskWrapperItem>
          <TkTaskWrapperItem padding="5px 1rem">
            <HorizontalDivider />
          </TkTaskWrapperItem>
        </TkTaskWrapper>
        {edited && (
          <TkTaskActions>
            <DynamicButton
              color="primary"
              disableElevation
              onClick={handleDismiss}
            >
              {t('cancel', { ns: 'common' })}
            </DynamicButton>
            <DynamicButton
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleSave}
            >
              {t('saveChanges', { ns: 'events' })}
            </DynamicButton>
          </TkTaskActions>
        )}
      </>
    );
  }
};

export default TkEditTaskDrawer;
