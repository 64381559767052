import React from 'react';

import Dropzone, { FileRejection } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Box } from '@mui/material';

import {
  ImageInput,
  ImagePreview,
  ImageInputList,
  DeleteIcon,
  AddNewImage,
  ImageWrapper,
} from './styles';
import TkIcon from '@components/TkIcon';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';

const TkDragNDrop = ({
  newImages,
  images,
  addImage,
  removeImage,
  removeUploadedImage,
  flag,
  disabled,
}: TkDropzoneProps): JSX.Element => {
  const { t } = useTranslation('events');
  const { addAlert } = useSnackbar();
  const triggerRemove = (
    i: File,
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    evt.stopPropagation();
    removeImage(i);
  };

  const ImagePreviewRender = ({
    key,
    src,
    onClick,
    disabled = false,
  }: {
    key: string;
    src: string;
    disabled?: boolean;
    onClick: () => void;
  }) => {
    return (
      <div key={key} style={{ position: 'relative' }}>
        {disabled ? null : (
          <DeleteIcon>
            <TkIcon
              color="navyBlue"
              className="fa fa-times-circle"
              onClick={onClick}
            />
          </DeleteIcon>
        )}
        <ImagePreview src={src} />
      </div>
    );
  };

  const showAlerts = (fileRejections: FileRejection[]) => {
    fileRejections.map((file) => {
      addAlert({
        message: `The image ${file.file.name} is bigger than 1MB in size`,
        severity: 'error',
      });
    });
  };

  if (!flag || flag === undefined) {
    return (
      <Dropzone
        accept="image/jpeg, image/jpg, image/png, image/bmp"
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length !== 0) addImage(acceptedFiles);
        }}
        onDropRejected={(fileRejections) => showAlerts(fileRejections)}
        maxSize={1024000}
      >
        {({ getRootProps, getInputProps }) =>
          newImages.length === 0 && images.length === 0 ? (
            <ImageInput {...getRootProps()}>
              <input {...getInputProps()} />
              <p>{t('dropdown.main')}</p>
            </ImageInput>
          ) : (
            <ImageInputList {...getRootProps()}>
              <AddNewImage {...getRootProps()}>
                <input {...getInputProps()} />
                Add Image
              </AddNewImage>
              <input {...getInputProps()} />
              {newImages.map((i) => (
                <div
                  key={i.name}
                  style={{ position: 'relative', marginTop: '5px' }}
                  {...getRootProps()}
                >
                  <DeleteIcon>
                    <TkIcon
                      color="navyBlue"
                      className="fa fa-times-circle"
                      onClick={(e) => triggerRemove(i, e)}
                    />
                  </DeleteIcon>
                  <ImagePreview src={URL.createObjectURL(i)} />
                </div>
              ))}
              {images.map((i) => (
                <div
                  key={i.id}
                  style={{ position: 'relative', marginTop: '5px' }}
                >
                  <DeleteIcon>
                    <TkIcon
                      color="navyBlue"
                      className="fa fa-times-circle"
                      onClick={() => removeUploadedImage(i.id)}
                    />
                  </DeleteIcon>
                  <ImagePreview src={i.fullImageUrl} />
                </div>
              ))}
            </ImageInputList>
          )
        }
      </Dropzone>
    );
  } else {
    return (
      <Box margin="10px 0">
        <ImageWrapper>
          {images.map((i) => (
            <ImagePreviewRender
              src={i.fullImageUrl}
              key={i.id}
              disabled={disabled}
              onClick={() => removeUploadedImage(i.id)}
            />
          ))}
          {newImages.map((i) => (
            <ImagePreviewRender
              src={URL.createObjectURL(i)}
              key={i.name}
              onClick={() => removeImage(i)}
            />
          ))}
        </ImageWrapper>
        {disabled ? (
          ''
        ) : (
          <Dropzone onDrop={(acceptedFiles) => addImage(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <ImageInput {...getRootProps()}>
                <input {...getInputProps()} />
                <p>{t('dropdown.main')}</p>
              </ImageInput>
            )}
          </Dropzone>
        )}
      </Box>
    );
  }
};

export default TkDragNDrop;
