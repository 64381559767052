import { useRecoilState } from 'recoil';

import { ApiResultsKind, LocalStorage } from '@consts/index';
import { getUserSettingsState } from '@contexts/AuthContext/state';
import { SessionUserSettings } from '@contexts/AuthContext/types/session';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { fetchUserSettings, updateUserSettings } from '@services/userMethods';

interface GetUserSettingsResponse {
  kind: ApiResultsKind;
  settings: SessionUserSettings | null;
}

interface UseUserSettings {
  userSettings: SessionUserSettings;
  handleSettingsUpdate: (
    settingsToUpdate: Partial<SessionUserSettings>
  ) => void;
  getUserSettings: () => Promise<GetUserSettingsResponse>;
  projectOfferWarning: ProjectOfferWarningLocalStorage;
  setProjectOfferWarning: (
    value:
      | ProjectOfferWarningLocalStorage
      | ((
          val: ProjectOfferWarningLocalStorage
        ) => ProjectOfferWarningLocalStorage)
  ) => void;
}

export const useUserSettings = (): UseUserSettings => {
  const [userSettings, setUserSettings] = useRecoilState(getUserSettingsState);
  const [projectOfferWarning, setProjectOfferWarning] =
    useLocalStorage<ProjectOfferWarningLocalStorage>(
      LocalStorage.PROJECT_OFFERS_WARNING,
      {
        dismissWarning: true,
        accounts: [],
      }
    );

  const getUserSettings = async () => {
    const { kind, settings } = await fetchUserSettings();
    if (kind === ApiResultsKind.OK) {
      return {
        kind: ApiResultsKind.OK,
        settings: settings as SessionUserSettings,
      };
    }
    return { kind: ApiResultsKind.ERROR, settings: null };
  };

  const handleSettingsUpdate = async (
    settingsToUpdate: Partial<SessionUserSettings>
  ) => {
    const { kind, settings: newSettings } = await updateUserSettings({
      settings: {
        ...userSettings,
        ...settingsToUpdate,
      },
    });
    if (kind === ApiResultsKind.OK) {
      setUserSettings(newSettings as SessionUserSettings);
    }
  };

  return {
    userSettings,
    getUserSettings,
    handleSettingsUpdate,
    setProjectOfferWarning,
    projectOfferWarning,
  };
};
