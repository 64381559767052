import * as QueryString from 'query-string';

/**
 * Returns the URL search value of the specified key if it exists in the query string.
 * Otherwise returns null.
 *
 * @param {string} rawSearch
 * @param {string} key
 * @return {string | null}
 */
export function getSearchParam(
  rawSearch: string,
  searchKey: string
): string | null {
  const searchOptions = QueryString.parse(rawSearch);
  if (typeof searchOptions !== 'object' || !(searchKey in searchOptions)) {
    return null;
  }

  const searchValue = searchOptions[searchKey];
  if (typeof searchValue !== 'string') {
    return null;
  }

  return searchValue;
}

/**
 * Returns the URL with the query params.
 *
 * @param {string} baseUrl
 * @param {Record<string, string>} params
 * @return {string}
 */
export function getQueryParams(
  baseUrl: string,
  params: Record<string, string>
): string {
  return `${baseUrl}?${QueryString.stringify(params)}`;
}
