import styled from 'styled-components';

export const ImageInput = styled.section`
  border: 1px dashed #4952a9;
  background-color: #f5f6fb;
  height: 64px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal normal 16px/24px 'Lato';
  text-align: center;
  color: #4952a9;
  cursor: pointer;

  input[type='file'] {
    display: none;
  }
`;

export const ImageInputList = styled(ImageInput)`
  display: flex;
  width: 100%;
  align-items: center;
  height: 100px;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 6px;
  scrollbar-width: thin;
  justify-content: left;
`;

export const ImageWrapper = styled.div`
  display: flex;
`;

export const ImagePreview = styled.img`
  height: 80px;
  width: 80px;
  border-radius: 4px;
  object-fit: cover;
`;

export const DeleteIcon = styled.div`
  display: flex;
  justify-content: right;
  opacity: 0;
  position: absolute;
  top: -6px;
  right: -2px;
  transition: 0.3s;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  height: 100%;

  &:hover {
    opacity: 1;
  }

  .MuiIcon-root {
    background-color: #ffffff;
    border-radius: 50%;
  }
`;

export const AddNewImage = styled.div`
  height: 80px;
  min-width: 80px;
  border-radius: 4px;
  margin: 5px 5px 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 10px;
  border: 1px dashed #4952a9;
  margin-bottom: 5px;
  user-select: none;
`;
