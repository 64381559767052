import React from 'react';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { Grid } from '@mui/material';

import {
  ModalContent,
  ModalActions,
  TextModal,
  SubTextModal,
  DeleteButtonGroup,
} from './styles';
import { TkTypography, TkIcon, DynamicButton } from '@components/index';
import { useModal } from '@contexts/index';

interface ISettings {
  handleCloseModalDeleted: () => void;
  deleteTask: (id: number) => Promise<BaseResult>;
  deleteRepeatingTask: (id: number, project_id: number) => Promise<BaseResult>;
  event: IEvent;
}

const ScTkDeleteTaskModalIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DeleteTaskModal = (props: ISettings): JSX.Element => {
  const { event, deleteTask, deleteRepeatingTask, handleCloseModalDeleted } =
    props;

  const { handleCloseModal } = useModal();
  const { t } = useTranslation(['events']);
  const queryClient = useQueryClient();

  const handleDeleteTask = (id: number) => {
    deleteTask(id).then((res) => {
      if (res) {
        queryClient.invalidateQueries('accountEvents');
        handleCloseModalDeleted();
      }
    });
  };

  const handleDeleteRepeatingTask = (id: number | null, project_id: number) => {
    if (id) {
      deleteRepeatingTask(id, project_id).then((res) => {
        if (res) {
          queryClient.invalidateQueries('accountEvents');
          handleCloseModal();
          handleCloseModalDeleted();
        }
      });
    }
  };

  return (
    <>
      <ModalContent>
        <div>
          <ScTkDeleteTaskModalIcon>
            <TkIcon
              className="fal fa-exclamation-triangle"
              iconSize={72}
              color="#F57846"
              width={81}
            />
          </ScTkDeleteTaskModalIcon>
          <TextModal>
            <TkTypography
              fontFamily="Muli"
              fontWeight="bold"
              fontSize={24}
              color="#011F41"
            >
              {event.isRecurring
                ? t('deleteModalTask.titleRecurring')
                : t('deleteX', { ns: 'common', item: event.title })}
            </TkTypography>
          </TextModal>
          <SubTextModal
            container //@ts-ignore
            justify="center"
            alignItems="center"
          >
            <Grid container justifyContent="center" alignItems="center">
              <TkTypography
                fontFamily="Lato"
                fontWeight="normal"
                fontSize={16}
                color="#011F41"
              >
                {event.isRecurring
                  ? t('deleteModalTask.middleTextRecurring')
                  : t('deleteModalTask.middleTextSingleTask')}
              </TkTypography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <TkTypography
                fontFamily="Lato"
                fontWeight="normal"
                fontSize={16}
                color="#011F41"
              >
                {t('deleteModalTask.bottomTextWarning')}
              </TkTypography>
            </Grid>
          </SubTextModal>
        </div>
      </ModalContent>
      <ModalActions>
        <Grid container>
          <Grid item xs={6}>
            <DynamicButton color="primary" onClick={handleCloseModal}>
              {t('cancel', { ns: 'common' })}
            </DynamicButton>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            {event.isRecurring ? (
              <DeleteButtonGroup>
                <DynamicButton
                  variant="outlined"
                  bgcolor="delete"
                  color="delete"
                  onClick={() => handleDeleteTask(event.id)}
                  disableElevation
                  style={{ marginRight: '20px' }}
                >
                  {t('deleteModalTask.singleTextDelete')}
                </DynamicButton>
                <DynamicButton
                  variant="contained"
                  bgcolor="delete"
                  color="delete"
                  onClick={() =>
                    handleDeleteRepeatingTask(
                      event.recurringProjectRuleId,
                      event.id
                    )
                  }
                  disableElevation
                >
                  {t('deleteModalTask.futureTasksText')}
                </DynamicButton>
              </DeleteButtonGroup>
            ) : (
              <DynamicButton
                variant="contained"
                bgcolor="delete"
                onClick={() => handleDeleteTask(event.id)}
                disableElevation
              >
                {t('delete', { ns: 'common' })}
              </DynamicButton>
            )}
          </Grid>
        </Grid>
      </ModalActions>
    </>
  );
};

export default DeleteTaskModal;
