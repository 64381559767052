import { useLocation } from 'react-router-dom';

import { AppLocationURLs } from '@consts/appUrls';
import {
  NotificationOptionsCustom,
  UseDesktopNotification,
} from '@hooks/desktop-notification/types';

export const useDesktopNotification = (): UseDesktopNotification => {
  const { pathname } = useLocation();

  if ('Notification' in window) {
    Notification.requestPermission();

    if (Notification.permission === 'denied') {
      console.log('Notifications are denied');
    }

    if (Notification.permission === 'granted') {
      console.log('Notifications are granted');
    }

    if (Notification.permission === 'default') {
      console.log('Notifications are default');
    }
  }

  const notify = (title: string, options?: NotificationOptionsCustom) => {
    if (
      document.visibilityState === 'visible' &&
      pathname === AppLocationURLs.CHAT
    )
      return;

    console.log('Notification', title);

    const notification = new Notification(title, options);

    notification.addEventListener('click', () => {
      if (options?.onClick) {
        window.focus();
        options.onClick();
        notification.close();
      }
    });

    return notification;
  };

  return {
    notify,
  };
};
